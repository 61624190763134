import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { VIEWS, VIEWS_PERMISSIONS } from '../ui/variables';
import Dashboard from '../pages/Dashboard'
import CreateReport from '../pages/CreateReport'
import CreateCategory from '../pages/CreateCategory'
import CreateConnection from '../pages/CreateConnection'
import ReportViewer from '../pages/ReportViewer'
import Reports from '../pages/Reports'
import { ViewLoader } from '../components/ViewLoader';
import { NotFound } from '../pages/notFound';
import { NotRoles } from '../pages/notRoles';

const routes = [
  {
    path: '/',
    component: withAuthenticationRequired(Dashboard, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.DASHBOARD]
  },
  {
    path: '/reporte/:id',
    component: withAuthenticationRequired(CreateReport, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.REPORTE]
  },
  {
    path: '/reportes',
    component: withAuthenticationRequired(Reports, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.REPORTES]
  },
  {
    path: '/categorias',
    component: withAuthenticationRequired(CreateCategory, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.CATEGORIAS]
  },
  {
    path: '/conexiones',
    component: withAuthenticationRequired(CreateConnection, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.CONEXIONES]
  },
  {
    path: '/ver-reporte/*',
    component: withAuthenticationRequired(ReportViewer, {
      onRedirecting: () => <ViewLoader />
    }),
    exact: true,
    role: VIEWS_PERMISSIONS[VIEWS.VER_REPORTE]
  },
  {
    path: '/NotRoles',
    component: NotRoles,
    role: VIEWS_PERMISSIONS[VIEWS.DASHBOARD]
  },
  {
    path: '*',
    component: NotFound,
    role: VIEWS_PERMISSIONS[VIEWS.DASHBOARD]
  }
];

export { routes };
